<template>
  <b-card title="Destinos">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Buscar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Buscar"
            type="text"
            class="d-inline-block"
          />
          <b-button
            class="ml-1"
            variant="primary"
            @click="modalCreateDestination = true"
          >
            <span class="text-nowrap">Crear nuevo destino</span>
          </b-button>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="destinations"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>

            <b-dropdown
              v-show="!loading"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item 
                @click="showModalEdit(props.row)">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>

              <b-dropdown-item 
                @click="deleteDestination(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50 text-danger"
                />
                <span class="text-danger">Eliminar</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item
                :to="{ name: 'order-detail', params: { order_id: props.row.id }}">
                <feather-icon
                  icon="ListIcon"
                  class="mr-50"
                />
                <span>Detalle</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 a
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} items </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal create corporate user-->
    <b-modal
        v-model="modalCreateDestination"
        id="modal-login"
        cancel-variant="outline-secondary"
        centered
        title="Crear destino"
        hide-footer
    >

      <b-card-text>
        Ingresa todos los datos para crear un destino
      </b-card-text>

      <validation-observer ref="addDestinationValidation">

        <b-form
        class="mt-2" 
            @submit.prevent="createDestination">

          <b-form-group>
            <label for="name">Nombre del destino:</label>
            <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
            >
              <b-form-input
                  v-model="name"
                  id="name"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="address">Dirección:</label>
            <validation-provider
                #default="{ errors }"
                name="Apellidos"
                rules="required"
            >
              <b-form-input
                  v-model="address"
                  id="address"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="city">Ciudad:</label>
            <validation-provider
                #default="{ errors }"
                name="Ciudad"
                rules="required"
            >
              <b-form-input
                  v-model="city"
                  id="city"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="contact_name">Nombre de contacto:</label>
            <validation-provider
                #default="{ errors }"
                name="Nombre de contacto"
                rules="required"
            >
              <b-form-input
                  v-model="contactName"
                  id="contact_name"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="contact_phone">Número de contacto:</label>
            <validation-provider
                #default="{ errors }"
                name="Número de contacto"
                rules="required"
            >
              <b-form-input
                  v-model="contactPhone"
                  id="contact_phone"
                  type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-spinner
              variant="primary"
              class="mt-4 mb-2 float-right"
              v-show="loading"></b-spinner>

          <!-- submit button -->
          <b-button
              variant="primary"
              type="submit"
              size="md"
              class="mt-2 mb-2 float-right"
              v-show="!loading"
          >
            Guardar
          </b-button>

        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal create corporate user-->
    <b-modal
        v-model="modalDestinationEdit"
        id="modal-login"
        cancel-variant="outline-secondary"
        centered
        title="Editar destino"
        hide-footer
    >

      <b-card-text>
        Ingresa todos los datos para editar un destino
      </b-card-text>

      <validation-observer ref="editDestinationValidation">

        <b-form
        class="mt-2" 
            @submit.prevent="editDestination">

          <b-form-group>
            <label for="name">Nombre del destino:</label>
            <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
            >
              <b-form-input
                  v-model="destinationForEdit.name"
                  id="name"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="address">Dirección:</label>
            <validation-provider
                #default="{ errors }"
                name="Apellidos"
                rules="required"
            >
              <b-form-input
                  v-model="destinationForEdit.address"
                  id="address"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="city">Ciudad:</label>
            <validation-provider
                #default="{ errors }"
                name="Ciudad"
                rules="required"
            >
              <b-form-input
                  v-model="destinationForEdit.city"
                  id="city"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="contact_name">Nombre de contacto:</label>
            <validation-provider
                #default="{ errors }"
                name="Nombre de contacto"
                rules="required"
            >
              <b-form-input
                  v-model="destinationForEdit.contact_name"
                  id="contact_name"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="contact_phone">Número de contacto:</label>
            <validation-provider
                #default="{ errors }"
                name="Número de contacto"
                rules="required"
            >
              <b-form-input
                  v-model="destinationForEdit.contact_phone"
                  id="contact_phone"
                  type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-spinner
              variant="primary"
              class="mt-4 mb-2 float-right"
              v-show="loading"></b-spinner>

          <!-- submit button -->
          <b-button
              variant="primary"
              type="submit"
              size="md"
              class="mt-2 mb-2 float-right"
              v-show="!loading"
          >
            Editar
          </b-button>

        </b-form>
      </validation-observer>
    </b-modal>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BSpinner, BButton, BForm, BCardText,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment';
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BSpinner,
    BButton,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BCardText,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      modalCreateDestination: false,
      locale: 'es',
      name: null,
      address: null,
      contactName: null,
      contactPhone: null,
      city: null,
      destinationForEdit: null,
      modalDestinationEdit: false,
      modalDeleteDestination: false,
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Nombre',
          field: 'name',
        },
        {
          label: 'Dirección',
          field: 'address',
        },
        {
          label: 'Ciudad',
          field: 'city',
        },
        {
          label: 'Contacto',
          field: 'contact_name',
        },
        {
          label: 'Número de contacto',
          field: 'contact_phone',
        },
        {
          label: 'Acciones',
          field: 'action',
        },
      ],
      destinations: [],
      searchTerm: '',
      status: [{
        1: 'En alistamiento',
        2: 'Despachado',
        3: 'Cancelado',
        4: 'Finalizado',
        5: 'Recibido',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      loading: false,
      required,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'En alistamiento'      : 'light-primary',
        Despachado : 'light-success',
        Cancelado     : 'light-danger',
        Finalizado     : 'light-warning',
        Recibido      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    localize(this.locale)

    this.getDestinations()

  },
  methods: {
    getDestinations () {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/destinations', { headers }).then(response => {
        console.log('destinations: ', response)

        this.destinations = response.data.data.destinations
        
      }).catch( err => {
        console.log(err)
      })
    },
    createDestination() {
      event.preventDefault()
      this.$refs.addDestinationValidation.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/destinations', {
            name: this.name,
            address: this.address,
            contact_name: this.contactName,
            contact_phone: this.contactPhone,
            city: this.city,
          }, { headers }).then(response => {
            console.log('get-destinations: ', response)

            if(response.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })

              this.name = null
              this.address = null
              this.contactName = null
              this.contactPhone = null
              this.city = null

              this.getDestinations()
              this.modalCreateDestination = false


            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }

          }).catch( err => {
            console.log(err)
          })

        }
      })
    },

    showModalEdit(destination) {
      console.log(destination)
      this.destinationForEdit = destination
      this.modalDestinationEdit = true
    },

    editDestination() {
      event.preventDefault()
      this.$refs.editDestinationValidation.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/destinations/' + this.destinationForEdit.id, {
            name: this.destinationForEdit.name,
            address: this.destinationForEdit.address,
            contact_name: this.destinationForEdit.contact_name,
            contact_phone: this.destinationForEdit.contact_phone,
            city: this.destinationForEdit.city,
          }, { headers }).then(response => {
            console.log('destinations: ', response)

            if(response.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })

              this.getDestinations()
              this.modalDestinationEdit = false

            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }

          }).catch( err => {
            console.log(err)
          })

        }
      })
    },

    deleteDestination(destination) {
      this.$bvModal
        .msgBoxConfirm('Por favor confirme si quiere eliminar el destino.', {
          title: 'Eliminar destino',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          console.log('eliminar')

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.delete(this.host + '/api/front-web/destinations/' + destination.id, { headers }).then(response => {
            console.log('delete-destinations: ', response)

            if(response.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })

              this.getDestinations()
              
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }

          }).catch( err => {
            console.log(err)
          })

        })
    },

    downloadProductsByOrderNumber(order_number) {

      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/download-products-by-order-number', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = order_number + ' - ' + date + '.xlsx';
          link.click();
          
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se ha descargado el archivo',
              icon: 'SlashIcon',
              variant: 'warning',
            },
          }) 
        }
      };
      let params = 'order_number=' + order_number;
      xhr.send(params)
      xhr.th

    }
  }
}
</script>
